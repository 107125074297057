import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from "lodash"
import store from '@/store/store'
import axios from 'axios';

Vue.use(VueRouter)

const routes = [
  ///// Home Page //////// 
  {
    path: '/',
    name: 'Home',
    // component: () => import('@/views/pages/setForgetPassword.vue'),
    component: () => import('@/views/home.vue'),
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/layouts/Login.vue'),
    children: [
      {
        path: '/login',
        name: 'Login View',
        meta: {
          title: 'Login',
          requiresAuth: false,

        },
        component: () => import('@/views/login.vue')
      },
      {
        path: '/forgotpassword',
        name: 'Forgotpassword View',
        meta: {
          title: 'Forgot Password',
          requiresAuth: false,

        },
        component: () => import('@/views/forgotPassword.vue')
      },
      {
        path: '/resetpassword',
        name: 'Resetpassword View',
        meta: {
          title: 'Reset Password',
          requiresAuth: false,

        },
        component: () => import('@/views/resetPassword.vue')
      },
      {
        path: '/public/set-password',
        name: 'Resetpassword View',
        meta: {
          title: 'Set Password',
          requiresAuth: false,

        },
        component: () => import('@/views/resetPassword.vue')
      },
      {
        path: '/createaccount',
        name: 'createaccount View',
        meta: {
          title: 'Create Account',
          requiresAuth: false,

        },
        component: () => import('@/views/createAccount.vue')
      },

      {
        path: '/public/reset-password',
        name: 'reset-password',
        // component: () => import('@/views/pages/setForgetPassword.vue'),
        component: () => import('@/views/resetPassword.vue'),
        meta: {
          requiresAuth: false
        }
      },

    ]
  },

  {
    path: '/subscribe-plans',
    name: 'subscribePlans',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/subscribePlans.vue')
  },

  //  After Login Layout
  {
    path: '/',
    name: 'dataview',
    component: () => import('@/layouts/Main.vue'),
    children: [

      {
        path: '/',
        name: 'Home',
        meta: {
          title: 'Root',
          requiresAuth: false,
          hideBackButton: true,
        },
        component: () => import('@/views/home.vue')
      },
      {
        path: '/dashboard',
        name: 'dashboardView',
        meta: {
          title: 'Dashboard',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/dashboard.vue')
      },
      {
        path: '/payments',
        name: 'paymentsView',
        meta: {
          title: 'Payments',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/payments.vue')
      },



      {
        path: '/userdetails',
        name: 'userdetailsView',
        meta: {
          title: 'Users',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/userDetails.vue')
      },
      {
        // path: '/viewItems/:itemId',
        path: '/restaurant-details/:restaurantId',
        name: 'Restaurant Details',
        meta: {
          title: 'Restaurant Details',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/restaurentsInfo.vue')
      },
      {
        path: '/contactList',
        name: 'contactListView',
        meta: {
          title: 'Contacts',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/contactList.vue')
      },
      {
        path: '/bulk-emails',
        name: 'emailsListView',
        meta: {
          title: 'Send Emails',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/bulkEmail.vue')
      },
      {
        path: '/viewItems/:itemId',
        // path: '/viewItems',
        name: 'viewItems',
        meta: {
          title: 'Catering Items',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/viewItems.vue')
      },
      {
        path: '/restaurantdetails',
        name: 'restaurantdetailsView',
        meta: {
          title: 'Restaurant',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/restaurentDetails.vue')
      },
      {
        path: '/customerdetails',
        name: 'customerdetailsView',
        meta: {
          title: 'Customers',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/customerDetails.vue')
      },
      {
        path: '/client-users',
        name: 'clientUsersList',
        meta: {
          title: 'Client Users',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/clientUsersList.vue')
      },


      {
        path: '/expenseslist',
        name: 'expenses-list',
        meta: {
          title: 'Expenses',
          requiresAuth: true,

        },
        component: () => import('@/views/expensesList.vue')
      },

      {
        path: '/orderlist',
        name: 'order-list',
        meta: {
          title: 'Orders',
          requiresAuth: true,

        },
        component: () => import('@/views/orderList.vue')
      },
      {
        path: '/incomelist',
        name: 'income-list',
        meta: {
          title: 'Income',
          requiresAuth: true,

        },
        component: () => import('@/views/incomeList.vue')
      },
      {
        path: '/cloverlist',
        name: 'clover-list',
        meta: {
          title: 'Orders',
          requiresAuth: true,

        },
        component: () => import('@/views/cloverorderList.vue')
      },
      {
        path: '/cateringOrder/:itemId',
        name: 'catering-order',
        meta: {
          title: 'Add Order',
          requiresAuth: true,
        },
        component: () => import('@/views/forms/cateringOrder.vue')
      },
      {
        path: '/:itemName/catering/:itemId',
        name: 'catering-order',
        meta: {
          title: 'Add Order',
          requiresAuth: false,
        },
        component: () => import('@/views/common/guestOrder.vue')
      },
      {
        path: '/order-details/:itemId',
        name: 'order-details',
        meta: {
          title: 'Order Details',
          requiresAuth: true,
        },
        component: () => import('@/views/orderDetails.vue')
      },
      {
        path: '/:orderId/cateringOrder/:itemId',
        name: 'catering-order',
        meta: {
          title: 'Edit Order',
          requiresAuth: true,
        },
        component: () => import('@/views/forms/cateringOrder.vue')
      },
      {
        path: '/order-details/:itemId/:logId',
        name: 'order-details',
        meta: {
          title: 'Order Details',
          requiresAuth: true,
        },
        component: () => import('@/views/orderDetails.vue')
      },

      {
        path: '/custom-menu/:itemId',
        name: 'custom-menu',
        meta: {
          title: 'Menu',
          requiresAuth: false,
        },
        component: () => import('@/views/customMenu.vue')
      },
      {
        path: '/specialsOrder/:itemId',
        name: 'catering-order',
        meta: {
          title: 'Add Order',
          requiresAuth: true,
        },
        component: () => import('@/views/specials/specialsOrder.vue')
      },
      {
        path: '/:orderId/specialsOrder/:itemId',
        name: 'catering-order',
        meta: {
          title: 'Edit Order',
          requiresAuth: true,
        },
        component: () => import('@/views/specials/specialsOrder.vue')
      },
      {
        path: '/:itemName/specials/:itemId',
        name: 'specials-order',
        meta: {
          title: 'Add Specials',
          requiresAuth: false,
        },
        component: () => import('@/views/specials/guestSpecialsOrder.vue')
      },
      {
        path: '/customer-orders',
        name: 'past-order-list',
        meta: {
          title: 'Past Orders',
          requiresAuth: false,

        },
        component: () => import('@/views/pastOrderlist.vue')
      },
      {
        path: '/:itemName/catering/:itemId/:orderId',
        name: 'catering-order',
        meta: {
          title: 'Edit Order',
          requiresAuth: false,
        },
        component: () => import('@/views/common/guestOrder.vue')
      },
      {
        path: '/:itemName/catering-reorder/:itemId/:orderId',
        name: 'catering-order',
        meta: {
          title: 'Create Order',
          requiresAuth: false,
        },
        component: () => import('@/views/common/guestOrder.vue')
      },
      {
        path: '/guest-order-details/:itemId',
        name: 'order-details',
        meta: {
          title: 'Order Details',
          requiresAuth: false,
        },
        component: () => import('@/views/guestOrderDetails.vue')
      },
      {
        path: '/:itemName/specials/:itemId/:orderId',
        name: 'specials-order',
        meta: {
          title: 'Edit Specials',
          requiresAuth: false,
        },
        component: () => import('@/views/specials/guestSpecialsOrder.vue')
      },
      {
        path: '/:itemName/specials-reorder/:itemId/:orderId',
        name: 'specials-order',
        meta: {
          title: 'Add Specials',
          requiresAuth: false,
        },
        component: () => import('@/views/specials/guestSpecialsOrder.vue')
      },
      {
        path: '/restaurentUpdate/:itemId',
        name: 'restaurentUpdateView',
        meta: {
          title: 'Restaurant',
          requiresAuth: true,
          hideBackButton: true,
        },
        component: () => import('@/views/restaurentUpdate.vue')
      },
      
    ]
  },

]

const router = new VueRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  routes
})





router.beforeEach((to, from, next) => {
  const user = store.getters.getuser;
  const screenWidth = window.innerWidth;
  const routedId = localStorage.getItem('restaurantId');
  const isLoggedIn = store.getters['isLoggedIn'];
  const isPaymentDone = localStorage.getItem('paymentStatus') === 'true';
  const isProfileCompleted = localStorage.getItem('isProfileCompleted') === 'true';
  const isPaymentRequired = localStorage.getItem('isPaymentRequired') === 'true';



  if(['/auto-login'].indexOf(to.path)> -1){
    const token = to.query.token;
    localStorage.setItem('token',token);
      if (token) {
        let postData = { };
        store.dispatch("getUserData", postData).then((res) => {
          if(res && res.userId){
            localStorage.setItem('UserData',res.userId);
            localStorage.setItem('token',res.accessToken);
            setTimeout(() => {
              next('/login')
            }, []);
            
          }
        }).catch((err)=>{
          conosle.log('err...',err)
        })

      }
  }
 
  if (to.meta.requiresAuth === false) {
    if ((['/resetpassword', '/guest-request'].indexOf(to.path) > -1 || ['/public/set-password'].indexOf(to.path) > -1 || ['/public/reset-password'].indexOf(to.path) > -1 || ['/createaccount'].indexOf(to.path) > -1 || to.path.includes('/cateringcghjg/') || to.path.includes('/custom-menu/')) && store.getters['isLoggedIn']) {
      localStorage.removeItem('token');
      localStorage.removeItem('role_id');
      localStorage.removeItem('user');
      delete axios.defaults.headers.common['Authorization']
      store.dispatch("logout");
    }
    
    if (store.getters['isLoggedIn'] && to.path != '/dashboard'&& !to.path.includes('/catering')&& !to.path.includes('/guest-order-details') && !to.path.includes('/specials') && !to.path.includes('/customer-orders')) {
      next('/dashboard');
      return;
    }
    // if (!store.getters['isLoggedIn'] && (screenWidth < 767 && to.path == '/')) {
    //   next('/login');
    //   return;
    // }

  } else {
    // if (store.getters['isLoggedIn']) {
    //   if (isPaymentDone === 'false') {
    //     console.log("............./",to)
    //     if (to.path !== '/subscribe-plans') {
    //       next('/subscribe-plans');
    //       return;
    //     }
    //   }
  
    //   if (isPaymentDone === 'true') {
    //     if (isProfileCompleted === 'false' && routedId) {
    //       next(`/restaurentUpdate/${routedId}`);
    //       return;
    //     } else {
    //       next('/dashboard');
    //       return;
    //     }
    //   }
    //   if (!store.getters['isLoggedIn'] && to.path != '/') {
    //     next('/');
    //     return;
    //   }
      
    // } else {
    //   next('/login')
    //   return
    // }

    if (to.meta.requiresAuth && !isLoggedIn) {
      next('/login');
    } else if (isPaymentRequired && isLoggedIn && !isPaymentDone && to.path !== '/subscribe-plans') {
      next('/subscribe-plans');
    } else if (isPaymentRequired && isLoggedIn && isPaymentDone && !isProfileCompleted && routedId && to.path !== `/restaurentUpdate/${routedId}`) {
      next(`/restaurentUpdate/${routedId}`);
    } else {
      next();
    }


  }
  
  if(!to.path.includes('cateringOrder') && !to.path.includes('specialsOrder') && !to.path.includes('order-details') && !to.path.includes('orderlist')){
    localStorage.removeItem('orderFilters')
  }
  next()
  
})


router.afterEach(() => {
  if (store.state.token && store.state.user['_id']) {
    let postData = { 'accessToken': store.state.token, 'userId': store.state.user['_id'] };
    store.dispatch("loginFromAccessToken", postData).then((res) => {
      store.dispatch("getMasterData", 'timezones')
        .then((res) => {
          let timeZonesList = [...res];
          if (timeZonesList) {
            if (localStorage.getItem('timezoneId')) {
              let timezone = this.timeZonesList.find(timezone => timezone.id == localStorage.getItem('timezoneId'));
              if (timezone && timezone.hasOwnProperty('name')) {
                localStorage.setItem('timezoneName', timezone.name)
              }
            }
          }
        }).catch((err) => {
        });
    }).catch((err) => {

    });
  } else {

  }



})




export default router
