import axios from '@/axios.js';
//import store from '@/store'
//import moment from 'moment'

import state from './state'

let restaurantId = ''
if (_.has(state, "restaurantId")) {
  restaurantId = state['restaurantId']
}


const actions = {
  loginFromAccessToken({ commit }, item) {
    if (restaurantId) {
      item = Object.assign(item, { "restaurantId": restaurantId })
    }
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token');
      const config = token
      ? { headers: { Authorization: `Bearer ${token}` } } 
      : {};
      
      axios.post("/users/details", item,config)
        .then((response) => {
          const token = item['accessToken'];//response.data.result.data.accessToken
          const userRole = response.data.result.roleId;
          const user = response.data.result;
          localStorage.setItem('userRole', userRole)
          // localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem('loginuserRole', userRole)
          // localStorage.setItem('loginToken', token)
          localStorage.setItem('loginUserData', JSON.stringify(user))
          localStorage.setItem('logintenantId', '')
          axios.defaults.headers.common['Authorization'] = token
          if (_.has(state, "restaurantId")) {
            restaurantId = state['restaurantId']
          }

          commit('auth_success', { accessToken: token, user: user, role_id: userRole })
          resolve(response)
        })
        .catch((error) => {
          console.log(error);
          reject(error.response.data.result)
        })
    })
  },



  login({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("/auth/login", item)
        .then((response) => {
          const token = response.data.result.data.accessToken;

          let postData = { 'accessToken': '' };
          postData['accessToken'] = token;
          localStorage.setItem('role_id', response.data.result.data.roleId)
          localStorage.setItem('token', token)
          localStorage.setItem('user', JSON.stringify(response.data.result.data))
          axios.defaults.headers.common['Authorization'] = token
          const user = response.data.result.data;
          commit('auth_success', user)
          resolve(response.data.result.data)
        })
        .catch((error) => {
          reject(error.response.data);
        }
        )
    })
  },


  logout({ commit }) {
    return new Promise((resolve) => {
      console.log(commit);
      commit('logout')
      localStorage.removeItem('token');
      localStorage.removeItem('role_id');
      localStorage.removeItem('user');
      localStorage.removeItem('restaurantId');
      localStorage.removeItem('logo');
      localStorage.removeItem('timezoneId');
      localStorage.removeItem("customerDetails")
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },

  getList({ commit }, { data, path = " " }) {
    return new Promise((resolve, reject) => {
      axios.post(path, data)
        .then((response) => {
          console.log(response)
          resolve(response.data.result)
        })
        .catch((error) => {

          reject(error);

        })
    })
  },

  commonAction({ commit }, { data, path = "" }) {

    return new Promise((resolve, reject) => {
      axios.post(path, data)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          // alert(JSON.stringify(error.response.data.result));
          // alert(error.response.data.message);
          reject(error.response.data);

        })
    })
  },
  uploadS3File({ commit }, postdata) {
    return new Promise((resolve) => {
      axios.post("/s3/upload", postdata)
        .then((response) => {
          console.log(response);
          resolve(response)
        })
        .catch((error) => {
          if (error.response) {
            resolve(error.response.data.result.message);
          }
        })
    })
  },


  getcountries() {
    return new Promise((resolve) => {
      let item = {
        page: 1,
        perpage: 1000,
        category: "countries",
        //tenantId: "5db7d79d6032453bd060ed9c",
      };
      //if (tenantId) {
      //item = Object.assign(item, { "tenantId": tenantId })
      //  }
      axios.post("/masterdata/list", item)
        .then((response) => {
          resolve(response.data.result.list)
        });
    })
  },
  getstates({ commit }, countryId) {
    return new Promise((resolve) => {
      let item = {
        matcher: {
          countryId: countryId
        },
        page: 1,
        perpage: 1000,
        category: "states",
        //tenantId: "5db7d79d6032453bd060ed9c",
      };

      // if (tenantId) {
      //   item = Object.assign(item, { "tenantId": tenantId })
      // }
      axios.post("/masterdata/list", item)
        .then((response) => {
          //  commit('getcountries')
          resolve(response.data.result.list)
        });
    })
  },
  getlocations({ commit }, obj) {
    return new Promise((resolve) => {
      let item = {
        matcher: {
          stateId: obj.stateId
        },
        page: 1,
        perpage: 1000,
        category: "locations",
        //tenantId: "5db7d79d6032453bd060ed9c",
      };
      // if (tenantId) {
      //   item = Object.assign(item, { "tenantId": tenantId })
      // }
      axios.post("/masterdata/list", item)
        .then((response) => {
          //  commit('getlocations')
          resolve(response.data.result.list)
        });
    })
  },
  addLocations({ commit }, obj) {
    return new Promise((resolve) => {
      let item = {
        page: 1,
        perpage: 1000,
        stateId: obj.stateId,
        name: obj.name,
        category: "locations",
        // tenantId: "5db7d79d6032453bd060ed9c",
      };

      // if (tenantId) {
      // item = Object.assign(item, { "tenantId": tenantId })
      // }
      axios.post("/masterdata/add", item)
        .then((response) => {
          resolve(response.data.result)
        });
    })
  },
  addCategory({ commit }, obj) {
    return new Promise((resolve,reject) => {
      let item = {
        page: 1,
        perpage: 1000,
        name: obj.name,
        category: obj.category ? obj.category : "item_categories",
        measurementDetails: obj.measurementDetails?obj.measurementDetails:'',
        categoryDetails:obj.categoryDetails?obj.categoryDetails:'',
        shops:obj.shops?obj.shops:'',
        isShop:obj.isShop? obj.isShop:''
      };

      // if (tenantId) {
      // item = Object.assign(item, { "tenantId": tenantId })
      // }
      axios.post("/masterdata/add", item)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error)=>{
          reject(error);
        })
    })
  },
  getMasterData({ commit }, category = '') {
    return new Promise((resolve) => {
      let item = {
        matcher: {
        },
        page: 1,
        perpage: 1000,
        category: category
      };
      if(category == 'contacts'){
        if (localStorage.getItem('restaurantId')) {
            item['matcher']['restaurantId'] = localStorage.getItem('restaurantId')
        }
      }
      axios.post("/masterdata/list", item)
        .then((response) => {

          resolve(response.data.result.list)
        });
    })
  },

  getMasterDataExpenses({ commit }, { category, restaurantId }) {
    return new Promise((resolve, reject) => {
      let item = {
        matcher: {},
        page: 1,
        perpage: 1000,
        category: category
      };
      if (restaurantId !== null) {
        item.matcher.restaurantId = restaurantId;
      }
      console.log('Payload sent to API000:', item);
      axios.post("/masterdata/list", item)
        .then((response) => {
          resolve(response.data.result.list);
        })
        .catch((error) => {
          console.error('API Error:', error);
          reject(error);
        });
    });
  },

  getDepartments({ commit }) {
    return new Promise((resolve) => {
      let item = {
        matcher: {
        },
        page: 1,
        perpage: 1000,
        category: "user_departments",
      };
      axios.post("/masterdata/list", item)
        .then((response) => {
          //  commit('getlocations')
          resolve(response.data.result.list)
        });
    })
  },
  userregister({ commit }, postdata) {

    // if(tenantId){
    //   postdata = Object.assign( postdata ,{"tenantId":tenantId})
    // }
    return new Promise((resolve, reject) => {
      axios.post("/users/register", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  userUpdate({ commit }, postdata) {

    // if(tenantId){
    //   postdata = Object.assign( postdata ,{"tenantId":tenantId})
    // }
    return new Promise((resolve, reject) => {
      axios.post("/users/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  addCatering({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/catering/add", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getUsersList({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/users/list", postdata)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getDocumentsList({ commit }, postdata) {
    // if (localStorage.getItem('restaurantId')) {
    //   postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    // }
    return new Promise((resolve, reject) => {
      axios.post("/document/list", postdata)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },


  getUserDetails({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/users/details", postdata)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  createCustomer({ commit }, postdata) {

    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/customer/create", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  updateCustomer({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/customer/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getcustomerList({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/customer/list", postdata)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getCustomerDetails({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/customer/details", postdata)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },


  getEmailsList({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/email-template/list", postdata)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  getSignedUrl({ commit }, postdata) {
    return new Promise((resolve) => {
      axios.post("s3/get-signed-url", postdata)
        .then((response) => {
          console.log(response);
          resolve(response)
        })
        .catch((error) => {
          if (error.response) {
            resolve(error.response.data.result);
          }
        })
    })
  },
  pdftoword({ commit }, postdata) {
    return new Promise((resolve) => {
      axios.post("convert/pdf-to-word", postdata, {
        responseType: 'blob',
      }).then((response) => {
        console.log(response);
        resolve(response)
      })
        .catch((error) => {
          if (error.response) {
            resolve(error.response.data.result);
          }
        })
    })
  },



  getStatsCount({ commit }, postdata) {
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/dashboard/stats-count", postdata)
        .then((response) => {
          resolve(response.data)

        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getTopFiveEvaluations({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/dashboard/top-five-evaluations", postdata)
        .then((response) => {
          resolve(response.data)

        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  showMostHandledCases({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/dashboard/most-cases-handled", postdata)
        .then((response) => {
          resolve(response.data)

        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getStatsChartView({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/dashboard/stats-chart-view", postdata)
        .then((response) => {
          resolve(response.data)

        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },


  forgot_password({ commit }, item) {
    return new Promise((resolve) => {
      axios.post("/auth/forgot-password", item)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { resolve(error.response) })
    })
  },
  updatePassword({ commit }, item) {

    return new Promise((resolve) => {
      axios.post("/auth/update-password", item)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { resolve(error.response) })
    })
  },



  getFilteredStatsChartView({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/dashboard/stats-chart", postdata)
        .then((response) => {
          resolve(response.data)

        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },


  getDueTodayEvaluations({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("dashboard/due-today", postdata)
        .then((response) => {
          resolve(response)

        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  signupCustomer({ commit }, postdata) {

    // if(tenantId){
    //   postdata = Object.assign( postdata ,{"tenantId":tenantId})
    // }
    return new Promise((resolve, reject) => {
      axios.post("/auth/signup", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },



  downloadAllFiles({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/s3/get-zipped-files", postdata, {
        responseType: 'blob',
      })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  expenseUpdate({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/expense/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  expenseCreate({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/expense/create", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getExpensesList({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/expense/list", postdata)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  orderUpdate({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/order/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  orderCreate({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/order/create", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getOrdersList({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/order/list", postdata)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  addMasterData({ commit }, postdata) {
    console.log('Hello...........', postdata)
    let restaurantId = postdata.restaurantId || localStorage.getItem('restaurantId');
    if (restaurantId) {
      postdata = Object.assign(postdata, { "restaurantId": restaurantId });
    }
    return new Promise((resolve) => {
      let item = {
        page: 1,
        perpage: 1000,
        name: postdata.name,
        restaurantId: restaurantId,
        category: postdata.category ? postdata.category : "expenses_nature",
        // tenantId: "5db7d79d6032453bd060ed9c",
      };

      // if (tenantId) {
      // item = Object.assign(item, { "tenantId": tenantId })
      // }

      axios.post("/masterdata/add", item)
        .then((response) => {
          resolve(response.data.result)
        });
    })
  },
  getCloverOrdersList({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/clover/list", postdata)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  incomeEdit({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/income/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  incomeCreate({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/income/create", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getIncomeList({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/income/list", postdata)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getDetails({ commit }, { data, path = "" }) {
    if (localStorage.getItem('restaurantId')) {
      data = Object.assign({ "restaurantId": localStorage.getItem('restaurantId') }, data);
    }
    if (localStorage.getItem('timezoneId')) {
      data = Object.assign(data, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    console.log('data',data)
    return new Promise((resolve, reject) => {
      axios.post(path, data)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  addRestaurant({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/createrestaurant", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  addDocument({ commit }, postdata) {
    // if (localStorage.getItem('restaurantId')) {
    //   postdata = Object.assign({ "restaurantId": localStorage.getItem('restaurantId') }, postdata);
    // }
     return new Promise((resolve, reject) => {
      axios.post("/document/create", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  updateDocument({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/document/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  updateRestaurant({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/update-restaurant", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  statsCount({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/dashboard/stats-expanse-income", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getRestaurantList({ commit }, postdata) {

    // if(tenantId){
    //   postdata = Object.assign( postdata ,{"tenantId":tenantId})
    // }
    return new Promise((resolve, reject) => {
      axios.post("/restaurantlist", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  updateCateringStatus({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/catering/updateStatus", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  updateCateringItems({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/catering/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getCateringList({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/catering/list", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  expenseUpdate({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/expense/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  incomeUpdate({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/income/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  orderUpdate({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/order/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getDashboardStats({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/dashboard/stats-count", postdata)
        .then((response) => {
          resolve(response.data.result)

        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  addTray({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/add-tray-type", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  updateTray({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/update-tray-type", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getTrayList({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/tray-type-list", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  orderCreateGuest({ commit }, postdata) {
    // if (localStorage.getItem('restaurantId')) {
    //   postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    // }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/order/guest-create", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  acknowledgeExpense({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/expense/acknowledgement", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getAckUsers({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/users/acknowledgement-list", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);
        })
    })
  },
  getExpenseStatsList({ commit }, postData) {
    return new Promise((resolve, reject) => {
      axios.post("/expenses/expenses-stats-count", postData)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message)
        })
    })
  },
  updateUserProfile({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/user/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getCashInhandStats({ commit }, postdata) {
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/dashboard/stats-process", postdata)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getOrderStatsCount({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/order/stats-count", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  getOrderAmountStatsCount({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/orders/amount-stats", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  getIncomeStatsCount({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/income/stats-count", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  deleteRestaurant({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/manage-active-status-restaurant", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          console.log('response----------', error.response.data.message)
          reject(error.response.data.message);

        })
    })
  },
  deleteDocument({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/document/status-update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          console.log('response----------', error.response.data.message)
          reject(error.response.data.message);

        })
    })
  },

  deleteTray({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/active-tray-type", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          console.log('response----------', error.response.data.message)
          reject(error.response.data.message);

        })
    })
  },
  getSpecialsList({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/users/specials_list", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },



  addSpecialsMenu({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/users/add_specials", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  updateSpecialsMenu({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/users/update_special", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  updateRestaurantSpecials({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/users/update_special_status", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  createCategoryApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/users/create-category", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  categoryListApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/users/category-list", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  createContact({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("contact/create", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getContactList({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("contact/list", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getContactDetails({ commit }, postdata) {
    if (localStorage.getItem('restaurantId')) {
      postdata = Object.assign(postdata, { "restaurantId": localStorage.getItem('restaurantId') })
    }
    if (localStorage.getItem('timezoneId')) {
      postdata = Object.assign(postdata, { "timezoneId": localStorage.getItem('timezoneId') })
    }
    return new Promise((resolve, reject) => {
      axios.post("/contact/details", postdata)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  contactUpdate({ commit }, postdata) {

    // if(tenantId){
    //   postdata = Object.assign( postdata ,{"tenantId":tenantId})
    // }
    return new Promise((resolve, reject) => {
      axios.post("/contact/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })

  },

  customerCreateApi({ commit }, postdata) {

    // if(tenantId){
    //   postdata = Object.assign( postdata ,{"tenantId":tenantId})
    // }
    return new Promise((resolve, reject) => {
      axios.post("/users/create-customer", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })

  },

  customerValidateApi({ commit }, postdata) {
    return new Promise((resolve, reject) => {
      axios.post("/users/validate-customer", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })

  },



  restaurantTaxesApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/restaurant-taxes", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  
  customerDetailsApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/customer/details", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  
  customerAddressApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/order/customer-address-update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  createShopApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/shop/create", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  shopListApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/shop/list", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  updateShopApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/shop/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  shopDetailsApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/shop/details", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  
  shopDeletesApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/shop/manage-status", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getIngredientDetails({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/ingredient-details", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  
  masterDataupdateIngredient({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/masterdata/update", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  ingredientDeleteApi({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/ingredient-delete", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },

  liveCateringItemsCreate({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/live-catering/create", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error.response.data.message);

        })
    })
  },
  getLiveCateringItem({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/live-catering/details", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error);

        })
    })
  },

  getCooksList({ commit }, postdata) {

    return new Promise((resolve, reject) => {
      axios.post("/user/cooks-list", postdata)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error);

        })
    })
  },

  sendBulkEmails({ commit },postData) {
    return new Promise((resolve, reject) => {
      axios.post("/contacts/send-email", postData)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error);

        })
    })
  },

  sendBulkEmailsList({ commit },postData) {
    return new Promise((resolve, reject) => {
      axios.post("/contacts/email-list", postData)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error);

        })
    })
  },
  
  createShopOrderApi({ commit },postData) {
    return new Promise((resolve, reject) => {
      axios.post("/orders/shop-order-create", postData)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error);

        })
    })
  },
  
  getShopOrderList({ commit },postData) {
    return new Promise((resolve, reject) => {
      axios.post("orders/shop-orders-list", postData)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error);

        })
    })
  },
  
  shopOrderUpdateApi({ commit },postData) {
    return new Promise((resolve, reject) => {
      axios.post("/orders/shop-order-update", postData)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error);

        })
    })
  },

  
  restaurantUserSignupApi({ commit },postData) {
    return new Promise((resolve, reject) => {
      axios.post("restaurantUser/signup", postData)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error);

        })
    })
  },
 
  restaurantPlansApi({ commit },postData) {
    return new Promise((resolve, reject) => {
      axios.post("/admin/restaurant-details", postData)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error); 

        })
    })
  },

  restaurantFreeTrailApi({ commit },postData) {
    return new Promise((resolve, reject) => {
      axios.post("/restaurant/is-free-trail", postData)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error); 

        })
    })
  },

  getUserData({ commit },postData) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('token');
    
      // Conditionally add headers only if token exists
      const config = token
        ? { headers: { Authorization: `Bearer ${token}` } }
        : {};
      axios.post("/auth/user-details", postData,config)
        .then((response) => {
          resolve(response.data.result)
        })
        .catch((error) => {
          reject(error); 

        })
    })
  },
 
  

}







export default actions;
